.bd-footer {
  background-color: $docs-dark;
  color: $white;

  @include media-breakpoint-up(sm) {
    text-align: left;
  }

  .small-prints {
    color: #00FFF8;
    font-weight: 600;
  }
}
