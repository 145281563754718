.algolia-autocomplete {
  display: block !important;
  flex: 1;

  .ds-dropdown-menu {
    width: 100%;
    min-width: 0 !important;
    max-width: none !important;
    padding: .75rem 0 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .175);

    @include media-breakpoint-up(md) {
      width: 175%;
    }

    // Caret
    &::before {
      display: none !important;
    }

    [class^="ds-dataset-"] {
      padding: 0 !important;
      overflow: visible !important;
      background-color: transparent !important;
      border: 0 !important;
    }

    .ds-suggestions {
      margin-top: 0 !important;
    }
  }

  .algolia-docsearch-suggestion {
    padding: 0 !important;
    overflow: visible !important;
  }

  .algolia-docsearch-suggestion--category-header {
    padding: .125rem 1rem !important;
    margin-top: 0 !important;
    font-size: .875rem !important;
    font-weight: 500 !important;
    color: $primary-bright !important;
    border-bottom: 0 !important;
  }

  .algolia-docsearch-suggestion--wrapper {
    float: none !important;
    padding-top: 0 !important;
  }

  // Section header
  .algolia-docsearch-suggestion--subcategory-column {
    float: none !important;
    width: auto !important;
    padding: 0 !important;
    text-align: left !important;
  }

  .algolia-docsearch-suggestion--content {
    float: none !important;
    width: auto !important;
    padding: 0 !important;

    // Vertical divider between column header and content
    &::before {
      display: none !important;
    }
  }

  .ds-suggestion {
    &:not(:first-child) {
      .algolia-docsearch-suggestion--category-header {
        padding-top: .75rem !important;
        margin-top: .75rem !important;
        border-top: 1px solid rgba(0, 0, 0, .1);
      }
    }

    .algolia-docsearch-suggestion--subcategory-column {
      display: none !important;
    }
  }

  .algolia-docsearch-suggestion--title {
    display: block;
    padding: .25rem 1rem !important;
    margin-bottom: 0 !important;
    font-size: .875rem !important;
    font-weight: 400 !important;
  }

  .algolia-docsearch-suggestion--text {
    padding: 0 1rem .5rem !important;
    margin-top: -.25rem;
    font-size: .875rem !important;
    font-weight: 400;
    line-height: 1.25 !important;
  }

  .algolia-docsearch-footer {
    float: none !important;
    width: auto !important;
    height: auto !important;
    padding: .75rem 1rem 0;
    font-size: .75rem !important;
    line-height: 1 !important;
    color: #767676 !important;
    border-top: 1px solid rgba(0, 0, 0, .1);
  }

  .algolia-docsearch-footer--logo {
    display: inline !important;
    overflow: visible !important;
    color: inherit !important;
    text-indent: 0 !important;
    background: none !important;
  }

  .algolia-docsearch-suggestion--highlight {
    color: #5f2dab;
    background-color: rgba(154, 132, 187, .12);
  }

  .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
    box-shadow: inset 0 -2px 0 0 rgba(95, 45, 171, .5) !important;
  }

  .ds-suggestion.ds-cursor .algolia-docsearch-suggestion--content {
    background-color: rgba(208, 189, 236, .15) !important;
  }
}
