/*!
 * Bootstrap Docs (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under the Creative Commons Attribution 3.0 Unported License. For
 * details, see https://creativecommons.org/licenses/by/3.0/.
 */
// Dev notes
//
// Background information on nomenclature and architecture decisions here.
//
// - Bootstrap functions, variables, and mixins are included for easy reuse.
//   Doing so gives us access to the same core utilities provided by Bootstrap.
//   For example, consistent media queries through those mixins.
//
// - Bootstrap's **docs variables** are prefixed with `$docs-`.
//   These custom colors avoid collision with the components Bootstrap provides.
//
// - Classes are prefixed with `.bd-`.
//   These classes indicate custom-built or modified components for the design
//   and layout of the Bootstrap docs. They are not included in our builds.
//
// Happy Bootstrapping!
// Load Bootstrap variables and mixins
@import "../../../../src/scss/functions";
// here to be available to "variables"
@import "../../../../src/scss/utilities/colors_vars";
@import "../../../../src/scss/variables";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../src/scss/custom/mixins/buttons";
// here to override any conflicting "node_modules/bootstrap/scss/functions" definitions
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins";
// Load docs components
@import "colors";
@import "nav";
@import "masthead";
@import "content";
@import "skiplink";
@import "sidebar";
@import "footer";
@import "component-examples";
@import "callouts";
@import "examples";
@import "index";
@import "clipboard-js";
@import "brandhead";
@import "custom-color";
@import "syntax";
@import "anchor";
@import "algolia";
