.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;

  h4 {
    margin-top: 0;
    margin-bottom: .25rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  code {
    border-radius: .25rem;
  }

  & + .bd-callout {
    margin-top: -.25rem;
  }
}

// Variations
@mixin bs-callout-variant($color) {
  border-left-color: darken($color, 10%);
  h4 {
    color: darken($color, 10%);
  }
}

.bd-callout-info {
  @include bs-callout-variant($docs-info);
}

.bd-callout-warning {
  @include bs-callout-variant($docs-warning);
}

.bd-callout-danger {
  @include bs-callout-variant($docs-danger);
}

.bd-callout-accessibility {
  @include bs-callout-variant($docs-accessibility);
}
