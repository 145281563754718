.square-color {
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  padding: 15px;
  margin-right: 15px;
  float: left;

  &.shadow {
    box-shadow: 0px 0px 30px 1px rgba(0, 0, 0, 0.3);
  }
}

.c-line {
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 8px;
}
