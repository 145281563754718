.bd-examples {
  .img-thumbnail {
    margin-bottom: .75rem;
  }

  h4 {
    margin-bottom: .25rem;
  }

  p {
    margin-bottom: 1.25rem;
  }

  @media (max-width: 480px) {
    margin-right: -.75rem;
    margin-left: -.75rem;
    & > [class^="col-"] {
      padding-right: .75rem;
      padding-left: .75rem;
    }
  }
}


