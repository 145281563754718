.main-content {
  color: #17324D;
}

.bottom-content {
  position: relative;
  background-color: $primary;
  color: white;
}

.particles-js-canvas-el {
  position: absolute;
  top: 0;
  pointer-events: none;
  display: block;
  vertical-align: bottom;
  height: auto;
  width: 100vw;
  background-attachment: fixed;
  margin: 0 -1rem; // horizontal scroll reset
  z-index: 0
}

.main-content--panel {
  background-color: #FFF;
  @include media-breakpoint-up(sm) {
    margin-top: -16px;
    box-shadow: 0 10px 80px 0 rgba(90, 103, 114, 0.1);
  }
}

.main-content--image {
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, .5));
}

.cover-container,
.cover-canvas {
  position: relative;
}
.cover-container {
  width: 80%;
  margin: 0 auto;
}
.cover-canvas {
  width: 100%
}

.cover-progress {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
